<template>
  <div class="relative inline-block text-left">
    <div>
      <button @click="open=!open" type="button" class="flex items-center w-full justify-center gap-x-1.5 rounded-full bg-white px-4 py-2" :class="buttonClasses">
        <slot name="title"></slot>
        <i class="fal fa-angle-down transition" :class="[open ? 'rotate-180' : 'rotate-0']"></i>
      </button>
    </div>
    <Transition>
      <div  v-if="open" v-on-click-outside="close" class="absolute z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-light focus:outline-none" :class="direction == 'right' ? 'right-0' : 'left-0'">
        <div>
          <div class="flex flex-col divide-y divide-light">
            <slot></slot>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import {vOnClickOutside} from '@vueuse/components'
const open = ref(false)

defineProps({
  buttonClasses:{
    type:String
  },
  direction:{
    type:String,
    default:'left'
  }
})
const close = () =>{
  open.value = false
}
</script>
