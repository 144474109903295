<template>
  <OnClickOutside @trigger="open = false">
    <div class="overflow-hidden transition-all relative">
      <div class="flex gap-2 items-center" @click="open = !open">
        <slot name="title">
        </slot>
        <div class=" pr-body">
          <i class="fal fa-angle-down transition" :class="open ? 'rotate-180':'rotate-0'"></i>

        </div>
      </div>
      <div class="transition-all " :style="`max-height:${height}px`" ref="content">
        <slot>
        </slot>
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";

const content = ref()
const open = ref(false)

const props = defineProps({
  icon: {
    default: false
  },
  iconText: {
    default: false
  }
})

const height = computed(() => {
  if (content.value && open.value) {
    return content.value.scrollHeight;
  }
  return 0;
})

</script>
