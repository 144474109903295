<template>
  <div class="max-w-[1920px] w-full mx-auto bg-white">
    <div class="flex justify-between gap-4 items-center py-4 px-body max-w-[1920px] mx-auto border-b">
      <NuxtLink :to="useRuntimeConfig().public.siteUrl[useLanguage().lang.value]">
        <img :src="`/logo_${lang}.svg`" class="w-[150px] xl:w-[220px]" alt="">
      </NuxtLink>

      <div class="hidden 3xl:flex xl:flex-1 items-center justify-center whitespace-nowrap bg-white">
        <div class="flex-1 flex justify-center items-center ">
          <div class="flex w-full">
            <div class="flex-1 flex items-center justify-center">
              <div v-for="menuItem in menu">
                <div v-if="!menuItem.children">
                  <div class="py-1 px-body hover:text-contrast transition">
                    <NuxtLink :to="menuItem.url">{{ menuItem.text }}</NuxtLink>
                  </div>
                </div>
                <div v-else>
                  <Dropdown button-classes="hover:text-contrast transition">
                    <template v-slot:title>{{ menuItem.text }}</template>
                    <NuxtLink :to="menuItem.url" class="p-2 flex items-center gap-2 hover:bg-light transition" v-for="menuItem in menuItem.children">
                      <i v-if="menuItem.icon" :class="menuItem.icon" aria-hidden="true"></i>
                      <span>{{menuItem.text }}</span>
                    </NuxtLink>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex gap-2 m-4 self-end">
<!--                <NuxtLink to="/orders" class="w-full">-->
<!--                  <Button light color="primary" class="w-full" bg-class="bg-white" text-color-class="text-contrast" additional-classes="w-full text-center">{{ useLanguage().t('my_orders') }}</Button>-->
<!--                </NuxtLink>-->
                <NuxtLink :to=" useUser().user ? '/' : '/login'">
                  <Button class="w-full" additional-classes="text-center w-full">{{ useUser().user ? useLanguage().t('my_account') : useLanguage().t('my_account') }}</Button>
                </NuxtLink>
              </div>
              <div class="relative inline-block text-left">
                <Dropdown button-classes="border-light border" direction="right">
                  <template v-slot:title><i class="fal fa-language"></i>{{ lang.toUpperCase() }}</template>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('de')">DE</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('fr')">FR</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('it')">IT</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('en')">EN</div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div @click="open = true" class="3xl:hidden cursor-pointer text-3xl">
        <i class="fal fa-bars"></i>
      </div>

      <Transition class="3xl:hidden">
        <div v-if="open" class="absolute z-10 top-0 right-0 left-0 bottom-0 overflow-hidden max-h-dvh">
          <div class="flex h-full flex-col">

            <div class="flex justify-between border-b items-center py-4 px-body">

              <NuxtLink to="/" class="justify-self-start">
                <img :src="`/logo_${lang}.svg`" class="w-[150px] xl:w-[220px]" alt="">
              </NuxtLink>

              <div class="flex gap-4 items-center h-8 bg-white">
                <Dropdown direction="right">
                  <template v-slot:title class="text-sm border"><i class="fal fa-language"></i>{{ lang.toUpperCase() }}</template>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('de')">DE</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('fr')">FR</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('it')">IT</div>
                  <div class="px-4 py-1 hover:bg-light transition" @click="setLanguage('en')">EN</div>
                </Dropdown>

                <div @click="open=false" class="cursor-pointer text-3xl">
                  <i class="fal fa-xmark"></i>
                </div>
              </div>
            </div>

            <div class="flex-1 bg-white">
              <div class="text-xl text-primary  mt-2">

                <div v-for="menuItem in menu">

                  <NuxtLink :to="menuItem.url" v-if="!menuItem.children" class="block py-2 px-body font-bold">{{ menuItem.text }}</NuxtLink>

                  <div v-else class="font-bold">

                    <div class="flex flex-col font-normal">

                      <Collapsible>
                        <template class="" v-slot:title>
                          <div class="font-bold bg-white text-xl py-2 pl-body">{{ menuItem.text }}</div>
                        </template>

                        <div class="flex flex-col bg-light py-2 gap-2">

                          <NuxtLink v-for="menuItem in menuItem.children" :to="menuItem.url" @click="open=false" class="flex gap-2 items-center text-base px-body ">
                            <div class="text-sm flex items-center justify-center rounded-full bg-[#CCC7CC] aspect-square p-2">
                              <i :class="menuItem.icon" aria-hidden="true"></i>
                            </div>
                            {{ menuItem.text }}
                          </NuxtLink>

                        </div>
                      </Collapsible>

                    </div>


                  </div>
                </div>


              </div>
            </div>

            <div class="flex flex-col gap-2 p-4 w-full bg-white">
              <NuxtLink :to=" useUser().user ? '/' : '/login'">
                <Button class="w-full" additional-classes="justify-center w-full">{{ useUser().user ? useLanguage().t('my_account') : useLanguage().t('my_account') }}</Button>
              </NuxtLink>
            </div>

          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
<script setup lang="ts">
import Collapsible from "~/components/Collapsible.vue";
import { useUser } from "../stores/user";


const open = ref(false);

const {setLanguage, lang} = useLanguage()
const siteUrl = computed(()=>{
  return useRuntimeConfig().public.siteUrl[lang.value]
});

defineProps({
  border: {
    default: true,
  },
  logo: {
    default: true,
  }
})

const menu = computed(()=>[
  {
    text: useLanguage().t('menu.risks.text'),
    url: siteUrl.value + useLanguage().t('menu.risks.link'),
    children: false,
  },
  {
    text: useLanguage().t('menu.how_to_protect.text'),
    url: siteUrl.value + useLanguage().t('menu.how_to_protect.link'),
    children: false
  },
  {
    text: useLanguage().t('menu.blog.text'),
    url: siteUrl.value + useLanguage().t('menu.blog.link'),
    children: false
  },
  {
    text: useLanguage().t('menu.experts.text'),
    url: siteUrl.value + useLanguage().t('menu.experts.link'),
  }
])

watch(()=>useRoute().path, ()=>{
  open.value = false
})
</script>
